@use '@angular/material' as mat;
@include mat.core();
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-material.css';
@import './sass/root';
@import './sass/mat-theme';



* { box-sizing: border-box; }
html, body {
  height: 100%;
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

a, a:hover, a:focus {
  text-decoration: none;
  color: inherit;
}

a.regular-link { 
  text-decoration: underline;
  color: blue;
  font-weight: 500;
}

.ag-theme-material {
  --ag-background-color: var(--genii-color-white);
  --ag-header-foreground-color: var(--genii-color-gray-300); 
  --ag-header-background-color: var(--genii-color-white);
  --ag-header-cell-hover-background-color: var(--genii-color-gray-100);
  --ag-header-cell-moving-background-color: var(--genii-color-brand-200);
  --ag-checkbox-checked-color: var(--genii-color-brand-500);
  --ag-row-hover-color: var(--genii-color-brand-50);
  --ag-selected-row-background-color: var(--genii-color-brand-500);

  .ag-header-cell {
    font-size: var(--genii-app-grid-header-font-size);
    font-weight: 500;
    line-height: 24px;
  }
}


.ag-icon {
  color: var(--genii-color-gray-300);
}

.ag-row-selected {
  color: var(--genii-color-white);
}

.ag-row-selected .ag-selection-checkbox .ag-checkbox {
  border: 1px solid white;
}

.ag-row-selected .ag-selection-checkbox .ag-checkbox-input-wrapper input {
  opacity: 100%;
}

.error-color { 
  color: mat.get-theme-color($genii-funds-theme, warn, text); 
}

.primary-color { 
  color: mat.get-theme-color($genii-funds-theme, primary, default); 
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.align-center { text-align: center; }
.align-right { text-align: right; }
.align-left { text-align: left; }

.mg-r, .mg-row, .mg { margin-right: var(--genii-gutter); }
.mg-l, .mg-row, .mg { margin-left: var(--genii-gutter); }
.mg-t, .mg-stack, .mg { margin-top: var(--genii-gutter); }
.mg-b, .mg-stack, .mg { margin-bottom: var(--genii-gutter); }
.pd-r, .pd-row, .pd { padding-right: var(--genii-gutter); }
.pd-l, .pd-row, .pd { padding-left: var(--genii-gutter); }
.pd-t, .pd-stack, .pd { padding-top: var(--genii-gutter); }
.pd-b, .pd-stack, .pd { padding-bottom: var(--genii-gutter); }


.fund-selector {
  width: var(--genii-fund-selector-content-width)!important;

  &__content {
    min-width: var(--genii-fund-selector-content-width)!important;
  }

  &__label {
    padding-right: 2rem;
  }
}

.mdc-linear-progress__buffer {
  display: block !important;
}

// reports
.material-app, .mat-sidenav-container, .map-sidenav-content {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.mat-drawer-container{
  background-color: white;
}

.main-container{
  width: 100%;
  height: 100%;
  display: -ms-grid;
  display: grid;
  grid-template-rows: [header-row-start header-row-end] 0 
                      [content-row-start content-row-end] auto 
                      [footer-row-start footer-row-end] 0;
  row-gap: 1px;
}

.content {
  grid-row: content-row-start / content-row-end;
  padding: 0px 0px 0px 0px;
}

.header {
  grid-row: header-row-start / header-row-end;
}

.footer {
  grid-row: footer-row-start / footer-row-end;
}

.wrapper {
  height: 100%;
  overflow-y: hidden;
}

.grid-content {
  height: 90%;
}

.pdfViewer.singlePageView .page {
  margin: 0;
}

.topnav__content {
  display: flex;
  justify-content: flex-end;
  padding-right: 60px;
  mat-form-field {
    padding-top: 20px;
    min-width: 100%;
  }
}

#grid-options {
  background-color: var(--genii-color-white);
}

.upload-data-dialog-styles .mat-mdc-dialog-container{
  min-width: 800px;
}

.color-success {
  color:green;
}