// Brand: #00a9e0

:root {
  --genii-color-brand-50: #e1f5fa; 
  --genii-color-brand-100: #b2e5f4;
  --genii-color-brand-200: #81d3ed;
  --genii-color-brand-300: #52c2e6;
  --genii-color-brand-400: #2fb5e3;
  --genii-color-brand-500: #00a9e0; // default
  --genii-color-brand-600: #009ad2;
  --genii-color-brand-700: #0088bf;
  --genii-color-brand-800: #0077ac;
  --genii-color-brand-900: #00578b;
  --genii-color-complementary-50: #f1e5f5;
  --genii-color-complementary-100: #dcbfe7;
  --genii-color-complementary-200: #c695d8;
  --genii-color-complementary-300: #af6bc8;
  --genii-color-complementary-400: #9f4dbc; // default
  --genii-color-complementary-500: #8e32b0;
  --genii-color-complementary-600: #802fa9;
  --genii-color-complementary-700: #6d29a1;
  --genii-color-complementary-800: #5c2598;
  --genii-color-complementary-900: #3c1d89;
  --genii-color-charcoal-50: rgb(213,215,217);
  --genii-color-charcoal-100: rgb(181,184,189);
  --genii-color-charcoal-200: rgb(144,149,156);
  --genii-color-charcoal-300: rgb(96,101,108);
  --genii-color-charcoal-400: rgb(75,79,84);
  --genii-color-black: rgb(10,10,10);
  --genii-color-white: rgb(255,255,255);
  --genii-color-gray-50: rgb(245,245,245);
  --genii-color-gray-100: rgb(235,235,235);
  --genii-color-gray-300:rgb(119, 119, 119);
  --genii-gutter: 1rem;
  --genii-app-topnav-height: 60px;
  --genii-app-content-height: calc(100vh - 60px);
  --genii-app-sidepanel-width: 40vw;
  --genii-app-sidepanel-max-width: 600px;
  --genii-spinner-z-index: 100000;
  --genii-fund-selector-content-width: 600px;
  --genii-fund-capital-event-date-selector-content-width: 300px;
  --genii-capital-event-level-selector-content-width: 300px;
  --genii-screens-header-height: 80px;
  --genii-app-grid-header-font-size: 15px;
  --genii-app-grid-header-font-weight: 500;
  --genii-app-grid-header-line-height: 24px;
}
